import React, { useEffect } from 'react';

const Slider = ({ slides, interval = 5000 }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentSlideIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, interval);

    return () => clearInterval(intervalId);
  }, [currentSlideIndex]);


  return (
    <div className="relative ">
      <div className="flex">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`w-full  ${
              index === currentSlideIndex ? 'block' : 'hidden'
            }`}
          >
            {slide.content}
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`mx-1 w-4 h-4 rounded-full ${
              index === currentSlideIndex ? 'bg-[#58d96b]' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
