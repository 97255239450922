import React from "react";
import Header from "../header";
import Image from "next/image";
import { stakecutLogo } from "../../../assets/images";
import SwiperComponent from "./Slider";
import Paginations from "./Slider";
import SlickSlider from "./Slider";
import Headers from "../header/Headers";

const Authlayout = ({ children }) => {
  const slides = [
    {
      content: (
        <div className="mt-5 ">
          <div className="mt-10 bg-[#F4F5F7] text-[#061938] [text-14px] lg:py-10 lg:px-10 py-4 px-6  rounded-[20px]">
            <p className="text-[#061938] lg:text-[13px] text-[12px] leading-[24px]    h-[95px]">
              I used to be a youth corper earning 19,800 a month, but now I make
              up to 3,000,000 naira a month selling on Stakecut. Affiliate
              marketing helped me buy a brand new Lexus, take several luxury
              vacations to countries like Seychelles, United Arab Emirates and
              Qatar, plus I even completed a house in my hometown
            </p>
            <div className="flex mt-3 items-center ">
              <Image
                src="/assets/images/princewill.webp"
                width="50"
                height="50"
                className="w-auto rounded-full"
                alt="testimonial"
              />
              <div className="flex flex-col ml-3">
                <p className="font-bold">PrinceWill</p>
                <p className="text-[#42526E]">Affiliate Marketer</p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="mt-5 ">
          <div className="mt-10 bg-[#F4F5F7] text-[#061938] [text-14px] lg:py-10 lg:px-10 py-4 px-6  rounded-[20px]">
            <p className="text-[#061938] lg:text-[13px] text-[12px] leading-[24px] h-[95px]">
              I went from a broke suicidal 300 Level student, deep in debt from
              a failed skin care business to making 100,000 Naria in the first
              month i Joined Stakecut. I am now able to take care of all my
              expenses in School - and life has never been better!
            </p>
            <div className="flex mt-3 items-center ">
              <Image
                src="/assets/images/sophia.webp"
                width="50"
                height="50"
                className="w-auto rounded-full"
                alt="testimonial"
              />
              <div className="flex flex-col ml-3">
                <p className="font-bold">Jomoni Sophia</p>
                <p className="text-[#42526E]">Affiliate Marketer</p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="mt-5 ">
          <div className="mt-10 bg-[#F4F5F7] text-[#061938] [text-14px] lg:py-10 lg:px-10 py-4 px-6 rounded-[20px]">
            <p className="text-[#061938] lg:text-[13px] text-[12px] leading-[24px] h-[95px]">
              I was a frustrated young man before joining Stakecut. I had lost
              thousands of naria due to bad decisions. I kept procrastinating
              for too long about joining Stakecut. Finally when i joined, I made
              over $400 the first month. Which is about N240,000. All profit.
            </p>
            <div className="flex mt-3 items-center ">
              <Image
                src="/assets/images/oluwaseun.webp"
                width="50"
                height="50"
                className="w-auto rounded-full"
                alt="testimonial"
              />
              <div className="flex flex-col ml-3">
                <p className="font-bold">Oluwaseun</p>
                <p className="text-[#42526E]">Affiliate Marketer</p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    // Add more slides as needed
  ];

  return (
    <section className="bg-white my-2 ">
      <div className="flex flex-col-reverse lg:flex-row w-12/12 lg:mx-3   rounded-0 lg:rounded-xl mx-auto justify-center items-center ">
        <div className=" lg:h-screen h-auto bg-no-repeat bg-cover lg:bg-[#205363]  lg:bg-authBg bg-none lg:w-[50%] lg:rounded-[12px] relative">
          <div className="w-full  overflow-hidden lg:mt-0  mt-10 flex flex-col justify-center lg:px-16 px-3 lg:py-3 py-2 lg:mx-0 mx-2 relative ">
            <div className="w-fit h-fit  my-16 lg:flex hidden">
              <Image
                src="/assets/images/stakecut-logo-white.svg"
                width={150}
                height={50}
                className="w-auto border-2"
                alt="stakecut logo"
              />
            </div>
            <div className="overflow-hidden">
              {/* <Image src={stakecutLogo} width="50" height="50" className="w-auto" alt="testimonial"/> */}

              <div className="lg:flex flex-col hidden mt-5 lg:mt-32 ">
                <h1 className="text-white lg:text-6xl text-5xl mb-2 font-inter font-bold">
                  Your Internet <br className="lg:flex hidden" /> Income Journey{" "}
                  <br className="lg:flex hidden" /> Starts Here
                </h1>
                <div className="text-[#F4F5F7] my-3">
                  <p className="text-[#fff] leading-[26px] mt-3 text-[18px]">
                    You are now only a few clicks away to unlocking{" "}
                    <br className="lg:flex hidden" /> your own home based
                    business.
                  </p>
                </div>
              </div>
              <div className=" lg:w-[90%] w-full mt-[20px] ">
                <SlickSlider slides={slides} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2  px-0 lg:px-12 flex items-center justify-center flex-col  ">
          <div className=" w-full lg:w-[480px]">
            <Headers className="lg:hidden flex" />
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Authlayout;
