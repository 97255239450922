import React from 'react';
import styles from './header.module.scss';
import Image from 'next/image';
import { darkLogo } from '../../../assets/images';
import Slider from '../auth-layout/Slider';


const Headers = () => {
  const slides = [
    { content:   <div className="mt-5 ">
    <div className="mt-10 bg-[#F4F5F7] text-[#061938] text-[12px] py-4 px-6 rounded-[20px]">
      <p className="text-[#061938] leading-[16px]">
        I used to be a youth corper earning 19,800 a month,
        but now I make up to 3,000,000 naira a month selling
        on Stakecut. Affiliate marketing helped me buy a brand
        new Lexus, take several luxury vacations to countries
        like Seychelles, United Arab Emirates and Qatar, plus
        I even completed a house in my hometown
      </p>
      <div className="flex mt-3 mb- items-center ">
        <Image
          src="/assets/images/princewill.webp"
          width="50"
          height="50"
          className="w-auto rounded-full"
          alt="testimonial"
        />
        <div className="flex flex-col ml-3">
          <p className="font-bold">PrinceWill</p>
          <p className="text-[#42526E]">Affiliate Marketer</p>
        </div>
      </div>
    </div>
  </div> },
    { content:   <div className="mt-5 ">
    <div className="mt-10 bg-[#F4F5F7] text-[#061938] text-[12px] py-4 px-6 rounded-[20px]">
      <p className="text-[#061938] leading-[16px]">
      I went from a broke suicidal 300 Level student, deep in debt from a failed skin care business to making 100,000 Naria in the first month i Joined Stakecut. I am now able to take care of all my expenses in School - and life has never been better!
      </p>
      <div className="flex mt-3 mb- items-center ">
        <Image
          src="/assets/images/sophia.webp"
          width="50"
          height="50"
          className="w-auto rounded-full"
          alt="testimonial"
        />
        <div className="flex flex-col ml-3">
          <p className="font-bold">Jomoni Sophia</p>
          <p className="text-[#42526E]">Affiliate Marketer</p>
        </div>
      </div>
    </div>
  </div> },
    { content:   <div className="mt-5 ">
    <div className="mt-10 bg-[#F4F5F7] text-[#061938] text-[12px] py-4 px-6 rounded-[20px]">
      <p className="text-[#061938] leading-[16px]">
        I was a frustrated young man before joining Stakecut. I had lost thousands of naria due to bad decisions. I kept procrastinating for too long about joining Stakecut.Ffinally when i joined,  I made over $400 the first month. WHich is about N240,000. All profit.
      </p>
      <div className="flex mt-3 mb- items-center ">
        <Image
          src="/assets/images/Oluwaseun.webp"
          width="50"
          height="50"
          className="w-auto rounded-full"
          alt="testimonial"
        />
        <div className="flex flex-col ml-3">
          <p className="font-bold">PrinceWill</p>
          <p className="text-[#42526E]">Affiliate Marketer</p>
        </div>
      </div>
    </div>
  </div> },
    // Add more slides as needed
  ];
  return (
    <div>


    <section className={styles.heading__container} >
      <div className='lg:hidden flex'>
        <Image src={darkLogo} alt="stakecut logo" />
      </div>

    </section>
      {/* <div>

      <Slider slides={slides} />
      </div> */}
    </div>
  );
};

export default Headers;
